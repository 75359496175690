/* istanbul ignore file  */

import { MemoryHistory } from 'history'
import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { CustomRouter } from 'routes/CustomRouter'
import { history } from 'utils/browserhistory'
import { setSessionUuId } from 'utils/helpers/usuarioHelper'
import { startAutomatedDoraService } from 'services/automatedDoraService'
import App from './App'
import './i18n'
import './index.css'
import { store } from './store'
import GoogleTagManagerHelper from './utils/helpers/googleTagManagerHelper'
import MixpanelHelper from './utils/helpers/mixpanelHelper'
import { OpenChatWhenInactiveProvider } from './shared/Contexts/OpenChatWhenInactive'
import * as scheduleLeadWebhook from '@rededor/schedule-lead-webhook'
import { settings } from 'shared/consts/settings'

window.CuraInit = {
  localAssetsPath: `${process.env.REACT_APP_PUBLIC_URL}/assets/`,
  theme: 'legacyDefault'
}

startAutomatedDoraService()

GoogleTagManagerHelper.inicializaGTM()
GoogleTagManagerHelper.disparaEventoGtmDePaginaCarregada()

MixpanelHelper.init()

if (settings.SCHEDULE_LEAD_WEBHOOK.ACTIVE) {
  scheduleLeadWebhook.init({
    utmSource: settings.SCHEDULE_LEAD_WEBHOOK.UTM_SOURCE,
    gaCookieName: settings.SCHEDULE_LEAD_WEBHOOK.GA_COOKIE_NAME,
    webhookToken: settings.SCHEDULE_LEAD_WEBHOOK.TOKEN
  })
}

setSessionUuId()
const container = document.getElementById('root')
if (!container) {
  throw new Error('Erro ao executar o container')
}

document.documentElement.style.setProperty(
  '--viewportHeight',
  `${visualViewport?.height}px`
)

visualViewport?.addEventListener('resize', () => {
  document.documentElement.style.setProperty(
    '--viewportHeight',
    `${visualViewport?.height}px`
  )
})

render(
  <StrictMode>
    <Provider store={store}>
      <CustomRouter history={history as MemoryHistory}>
        <OpenChatWhenInactiveProvider>
          <App />
        </OpenChatWhenInactiveProvider>
      </CustomRouter>
    </Provider>
  </StrictMode>,
  container
)
