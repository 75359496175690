import { v4 as uuidv4 } from 'uuid'
import mixpanel, { Config } from 'mixpanel-browser'
import * as MixPanelModels from 'models/MixPanel'
import { store } from 'store'
import { obtemNomeDaPaginaAtual } from './stepBarHelpers/stepbarHelpers'
import { getSessionId } from './usuarioHelper'
import { acessoLogadoEstaAtivo } from './acessoLogadoHelper'
import { getStringValueOrUndefined } from './getStringValueOrUndefined'
import MixPanelPropriedades from '../../shared/consts/mixpanel/propriedades'
import mixpanelConfig from '../../shared/consts/mixpanel/token'
import ArmazenamentoHelper from './armazenamentoHelper'
import { settings } from 'shared/consts/settings'

const { MIXPANEL_DEVICE_ID_PROPERTY, MIXPANEL_DEVICE_ID_SESSION_KEY } = settings

class MixpanelHelper {
  private static readonly _token = mixpanelConfig.token
  private static readonly _config: Partial<Config> = {
    loaded: mixpanelObj => {
      const deviceId = mixpanelObj.get_property(MIXPANEL_DEVICE_ID_PROPERTY)
      ArmazenamentoHelper.armazenaNoLocalStorage(
        MIXPANEL_DEVICE_ID_SESSION_KEY,
        deviceId
      )
    },
    stop_utm_persistence: true
  }

  static _saveUtm() {
    const { search } = location
    const hasUtmParams = search.toString().includes('utm')
    if (!hasUtmParams) {
      return
    }

    const urlParams = new URLSearchParams(search)
    const utmParam = {
      utm_source: urlParams.get('utm_source') || undefined,
      utm_medium: urlParams.get('utm_medium') || undefined,
      utm_campaign: urlParams.get('utm_campaign') || undefined,
      utm_term: urlParams.get('utm_term') || undefined,
      utm_content: urlParams.get('utm_content') || undefined
    }
    ArmazenamentoHelper.armazenaNoSessionStorage(
      'utm',
      JSON.stringify(utmParam)
    )
  }

  static _getUtm() {
    const utm = ArmazenamentoHelper.recuperaDoSessionStorage('utm')
    if (!utm) {
      return {
        utm_source: undefined,
        utm_medium: undefined,
        utm_campaign: undefined,
        utm_term: undefined,
        utm_content: undefined
      }
    }
    return JSON.parse(utm)
  }

  static init() {
    mixpanel.init(MixpanelHelper._token, MixpanelHelper._config)
    this._saveUtm()
    const utm = this._getUtm()
    mixpanel.register(utm)
  }

  static reset() {
    mixpanel.reset()
  }

  static trackEvent(
    eventName: string,
    data?: MixPanelModels.TrackEvent,
    completedCallback?: () => void
  ) {
    const commomInformation: Record<string, unknown> = {
      [MixPanelPropriedades.APLICACAO_NOME]: 'Consultas',
      [MixPanelPropriedades.APLICACAO_VERSAO]: `v3 ${process.env.REACT_APP_VERSION}`,
      [MixPanelPropriedades.APP_SESSION_ID]: getSessionId(),
      is_pwa: Boolean(window.matchMedia('(display-mode: standalone)').matches)
    }

    if (data) {
      const dataToSend = {
        ...this.replaceKeysFromEventObject(data as Record<string, unknown>),
        ...commomInformation
      }

      mixpanel.track(eventName, dataToSend, completedCallback)

      return
    }

    mixpanel.track(eventName, commomInformation)
  }

  private static replaceKeysFromEventObject(
    eventObject: Record<string, unknown>
  ) {
    const objectWithKeysReplaced: Record<string, unknown> = {}

    Object.keys(eventObject).forEach(key => {
      objectWithKeysReplaced[
        (MixPanelPropriedades as Record<string, string>)[key]
      ] = eventObject[key]
    })

    return objectWithKeysReplaced
  }

  private static buildDataFluxoDadosRelevantes(): MixPanelModels.FluxoDadosRelevantes {
    const {
      escolhaPorMedico,
      medico,
      especialidade,
      agendamentoTeleconsulta,
      local,
      pagamento,
      paciente,
      unidade,
      codigoRequisicao
    } = store.getState().consulta

    const { ativo, usuarios } = store.getState().acessoLogado

    const usuarioRealizandoConsulta = usuarios?.logado.titular
      ? usuarios.logado.cpf
      : usuarios?.logado.cpfTitular

    const eventData: MixPanelModels.FluxoDadosRelevantes = {
      MODALIDADE: agendamentoTeleconsulta ? 'TELECONSULTA' : 'PRESENCIAL',
      FLUXO_DE_ATENDIMENTO: escolhaPorMedico
        ? 'MÉDICO ESPECÍFICO'
        : 'ESPECIALIDADE',
      MEDICO_CODIGO: getStringValueOrUndefined(medico?.id),
      MEDICO_NOME: medico?.nome,
      ESPECIALIDADE_CODIGO: especialidade?.id,
      ESPECIALIDADE_DESCRICAO: especialidade?.descricao,
      LOCAL_UF: local?.uf,
      LOCAL_CODIGO: getStringValueOrUndefined(local?.id),
      LOCAL_DESCRICAO: local?.nome,
      LOCAL_TIPO: local?.tipo,
      PACIENTE_DATA_NASCIMENTO: paciente?.dataNascimento,
      PACIENTE_GENERO: paciente?.genero,
      FORMA_DE_PAGAMENTO: pagamento
        ? pagamento.particular
          ? 'PARTICULAR'
          : 'PLANO'
        : undefined,
      CONVENIO_CODIGO: pagamento?.convenio
        ? String(pagamento?.convenio?.idMotion)
        : undefined,
      CONVENIO_NOME: pagamento?.convenio?.nome,
      PLANO_CODIGO: pagamento?.plano
        ? String(pagamento?.plano?.idMotion)
        : undefined,
      PLANO_NOME: pagamento?.plano?.nome,
      UNIDADE_NOME: unidade?.nome,
      UNIDADE_CODIGO: String(unidade?.id),
      UNIDADE_SETOR: unidade?.setorId,
      CODIGO_REQUISICAO: codigoRequisicao,
      LOGADO: acessoLogadoEstaAtivo(),
      ETAPA: obtemNomeDaPaginaAtual(),
      USUARIO: ativo ? usuarioRealizandoConsulta : 'Não indentificado'
    }

    return eventData
  }

  static sendEventGeneric<Type>(
    eventName: string,
    data?: MixPanelModels.FluxoDadosRelevantes & Type,
    completedCallback?: () => void
  ): void {
    const eventData: MixPanelModels.ModelCallToAction = {
      ...this.buildDataFluxoDadosRelevantes(),
      ...data
    }

    MixpanelHelper.trackEvent(eventName, eventData, completedCallback)
  }

  static getDeviceId() {
    let deviceId = ArmazenamentoHelper.recuperaDoLocalStorage(
      MIXPANEL_DEVICE_ID_SESSION_KEY
    )
    if (!deviceId) {
      deviceId = uuidv4()
      ArmazenamentoHelper.armazenaNoLocalStorage(
        MIXPANEL_DEVICE_ID_SESSION_KEY,
        deviceId
      )
    }

    return deviceId
  }
}

export default MixpanelHelper
