import { sessionKeys } from 'shared/consts/sessionKeys'
import ArmazenamentoHelper from 'utils/helpers/armazenamentoHelper'

const _isDoraSource = (params: URLSearchParams) => {
  const isDoraCampaign = params.get('utm_campaign') === 'dora_automatizada'
  const hasDoraIntegrationId = params.has('sessionId')

  return isDoraCampaign && hasDoraIntegrationId
}

export const hasDoraIntegrationId = () => {
  return (
    ArmazenamentoHelper.recuperaDoSessionStorage(
      sessionKeys.DORA_INTEGRATION_ID
    ) !== null
  )
}

export const getDoraIntegrationId = () => {
  return ArmazenamentoHelper.recuperaDoSessionStorage(
    sessionKeys.DORA_INTEGRATION_ID
  )
}

export const removeDoraIntegrationId = () => {
  ArmazenamentoHelper.removeDoSessionStorage(sessionKeys.DORA_INTEGRATION_ID)
}

export const getDoraIntegrationData = () => {
  return hasDoraIntegrationId()
    ? {
        doraIntegrationId: getDoraIntegrationId() as string
      }
    : {}
}

export const startAutomatedDoraService = () => {
  const params = new URLSearchParams(location.search)

  if (!_isDoraSource(params)) {
    return
  }

  const doraIntegrationId = params.get('sessionId') || ''

  ArmazenamentoHelper.armazenaNoSessionStorage(
    sessionKeys.DORA_INTEGRATION_ID,
    doraIntegrationId
  )
}
