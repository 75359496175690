/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import { acessoLogadoReducer } from './acessoLogado/reducers'
import { consultaReducer } from './consulta/reducers'
import { errorReducer } from './error/reducer'
import { excecaoReducer } from './excecao/reducers'
import { stepBarReducer } from './stepbar/reducers'
import { bannerReducer } from './Banner/reducers'
import { filterReducer } from './filtros/reducer'

const composeEnhancers: any =
  process.env.REACT_APP_NODE_ENV !== 'production'
    ? composeWithDevTools
    : compose

const rootReducer = combineReducers({
  consulta: consultaReducer,
  stepbar: stepBarReducer,
  excecao: excecaoReducer,
  acessoLogado: acessoLogadoReducer,
  error: errorReducer,
  banner: bannerReducer,
  filters: filterReducer
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
  devTools: composeEnhancers
})

export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
