import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import headersEndpointsAgendamento from '../shared/consts/headersEndpointAgendamento'
import { store } from '../store'
import {
  armazenarErroGenericoBackEnd,
  setRetryBadGateway
} from '../store/error/actions'
import { sessionKeys } from 'shared/consts/sessionKeys'
import { getSessionId } from 'utils/helpers/usuarioHelper'
import MixpanelHelper from '../utils/helpers/mixpanelHelper'

class RequisicaoAdapter {
  private static _axios: AxiosInstance
  private static readonly _retry = 4
  private static readonly _delay = 5000

  private static get axios() {
    if (!this._axios) {
      this.initAxios()
    }

    return this._axios
  }

  private static initAxios() {
    console.log('RequisicaoAdapter->initAxios')

    this._axios = axios.create({
      headers: {
        ...headersEndpointsAgendamento,
        'x-origin-referrer': document.referrer,
        'x-device-id': MixpanelHelper.getDeviceId(),
        [sessionKeys.REQUEST_SESSION_ID]: getSessionId()
      }
    })

    axiosRetry(this._axios, {
      retries: this._retry,
      retryDelay: (retryCount, error) => {
        const is504Error = error.response?.status === 504
        const is502Error = error.response?.status === 502
        const isTimeOutError = error.code === 'ERR_NETWORK'

        if (isTimeOutError || is504Error) return retryCount * this._delay * 2
        if (is502Error) return retryCount * this._delay

        return this._delay
      },
      retryCondition: error => {
        const is502Error = error.response?.status === 502
        const is504Error = error.response?.status === 504
        const isTimeOutError = error.code === 'ERR_NETWORK'

        return isTimeOutError || is502Error || is504Error
      },
      onRetry: (retryCount, error, requestConfig) => {
        // parametros sendo duplicados durante o retry
        requestConfig.url = requestConfig.url?.split('?')[0] || ''

        requestConfig.headers = {
          ...requestConfig.headers,
          'Retry-Count': retryCount
        }

        store.dispatch(setRetryBadGateway(true))
      },
      shouldResetTimeout: false
    })
  }

  public static async request<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axios.request<T>(config)
      store.dispatch(setRetryBadGateway(false))

      return response
    } catch (error: any) {
      if (error?.response?.status === 503) {
        window.location.href = 'em-manutencao'
      }

      store.dispatch(armazenarErroGenericoBackEnd(error))
      store.dispatch(setRetryBadGateway(false))

      throw error
    }
  }
}

export default RequisicaoAdapter
